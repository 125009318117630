<template>
  <div :class="{ 'wgt-stickyLift': true }" draggable="false">
    <div class="wgt-bd">
      <div class="wgt-bd-item">
        <div class="wgt-bd-title" :style="navStyle">
          <p class="title" v-for="(item, indx) in value.data" :key="`sticky_lift_title_${indx}`" :style="{
            color: curIndex == indx ? value.style.checked_color_font : value.style.default_color_font,
            backgroundColor: curIndex == indx ? value.style.checked_color : value.style.default_color
          }">{{ item.title }}</p>
        </div>
        <div class="wgt-bd-content" v-for="(ele, indx) in value.data" :key="`sticky_lift_item_${indx}`">
          <div v-for="(item, index) in ele.children" :key="`sticky_lift_item_imgs_${index}`">
            <div class="long_pic" v-if="item.type == 'horizontal' && item.imgUrl">
              <div class="long_pic_content" :ref="`nav_${index}`">
                <div class="carousel">
                  <SpImage :src="item.imgUrl" :style='`width:500px`' />
                </div>
              </div>
              <el-progress v-if="item.showProgress" :percentage="percentage" color="#1D1D1F" :stroke-width="4"
                :show-text="false"></el-progress>
            </div>
            <div class="long_pic" v-if="item.type == 'defaultzone' && item.imgUrl">
              <SpImage :src="item.imgUrl" />
            </div>
            <div class="long_pic" v-if="item.type == 'hotzone' && item.imgUrl">
              <SpImage :src="item.imgUrl" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from './config'
export default {
  name: "StickyLift",
  wgtName: '电梯',
  wgtDesc: '',
  wgtIcon: 'wgt-sticky-lift',
  wgtType: 1,
  wgtTem: 1,//0代表微信和抖音小程序都可以配置,1代表只能配置微信
  config,
  props: {
    value: [Object, Array]
  },
  data() {
    return {
      percentage: 0,
      prohibitDrag: false,
      isDown: false, // 是否按下鼠标
      startX: 0, // 鼠标起始位置
      scrollLeft: 0,// 滚动条位置
      height: 337,
      defaultPercentage: 0,
      curIndex: 0,
    };
  },
  methods: {
    move(e, currencyItemsRef) {
      const { scrollWidth, offsetWidth } = currencyItemsRef
      if (!this.isDown || scrollWidth == offsetWidth) return;
      e.preventDefault();
      const x = e.pageX - currencyItemsRef.offsetLeft;
      const walk = (x - this.startX) * 2; // 滑动距离
      let left
      if (walk > 0) {
        left = this.scrollLeft - offsetWidth;
      } else {
        left = this.scrollLeft + offsetWidth;
      }
      currencyItemsRef.scrollLeft = left
      const percentage = left * 1.0 / (scrollWidth - offsetWidth)
      if (percentage > 1) {
        this.percentage = 100
      } else if (percentage < this.defaultPercentage / 100) {
        this.percentage = this.defaultPercentage
      } else {
        this.percentage = percentage * 100
      }
    },
    setPercentage(index) {
      setTimeout(() => {
        const currencyItemsRef = this.$refs[`nav_${index}`][0]
        const { scrollWidth, offsetWidth } = currencyItemsRef
        const defaultPercentage = 100 / (scrollWidth / offsetWidth)
        this.defaultPercentage = defaultPercentage
        if (scrollWidth == offsetWidth) {
          this.percentage = 100
        } else {
          this.percentage = defaultPercentage
          this.setDrag(currencyItemsRef)
        }
      }, 1000)
    },
    setDrag(currencyItemsRef) {
      currencyItemsRef.addEventListener('mousedown', (e) => {
        this.isDown = true;
        this.startX = e.pageX - currencyItemsRef.offsetLeft;
        this.scrollLeft = currencyItemsRef.scrollLeft;
      });
      currencyItemsRef.addEventListener('mouseup', (e) => {
        this.isDown = false;
      });
      currencyItemsRef.addEventListener('mouseenter', (e) => { this.isDown = false; });
      currencyItemsRef.addEventListener('mousemove', (e) => this.move(e, currencyItemsRef));
    },
  },
  watch: {
    'value.data': {
      handler(val) {
        val.forEach(async (element) => {
          element.children.forEach((item, index) => {
            if (item.type == 'horizontal') {
              const { imgUrl = '' } = item
              this.$nextTick(async () => {
                if (imgUrl) {
                  await this.setPercentage(index)
                }
              })
            }
          })
        });
      },
      deep: true,
      immediate: true,

    }
  },
  computed: {
    navStyle() {
      return {
        background: this.value.style.bg_color,

      }
    }
  }
}
</script>

<style lang="scss">
.wgt-stickyLift {
  &.padded {
    padding: 10px 0;
  }

  .wgt-hd {
    padding: 10px;

    .title {
      font-size: 18px;
      font-weight: 600;
      color: #333;
    }

    .sub-title {
      color: #666;
      margin-left: 4px;
    }
  }

  .wgt-bd {
    .wgt-bd-title {
      // position: sticky;
      // top: 64px;
      // left: 0;
      // right: 0px;
      // z-index: 99;
      width: 100%;
      height: 45px;
      max-height: 45px;
      background: #141415;
      overflow-x: auto;
      box-sizing: border-box;
      color: #c9c9cb;
      height: 45px;
      line-height: 45px;
      font-size: 13px;
      display: flex;
      overflow: hidden;

      .title {
        flex-shrink: 0;
        flex-shrink: 0;
        padding: 0 18px;
        box-sizing: border-box;
      }
    }

    .long_pic {
      overflow: hidden;
      position: relative;

      .el-progress {
        width: 50%;
        margin: 10px auto 10px;
      }

      &_content {
        overflow-x: scroll;
        overflow-y: hidden;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;

        &::-webkit-scrollbar {
          display: none;
        }

        .carousel {
          width: 500px;
        }
      }
    }

    &.spaced {
      padding: 0 10px;

      .slider-container {
        right: 10px;
        left: 10px;
      }

      .slider-pagination {
        right: 10px;
        left: 10px;
      }
    }
  }
}
</style>
// 店铺装修
import {
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtProductShelves,
  WgtContentPart,
  WgtLift,
  WgtCustomWgt,
  WgtImageText,
  WgtMemberInfo,
  WgtSuspension,
  WgtUserContainer,
  WgtOfficialAccount
} from '../wgts/index'

export const initiWidgets = []

export const widgets = {
  
  WgtNewSlider,
  WgtHotZone,
  WgtHoriZontal,
  WgtblokScroll,
  WgtFilm,
  WgtAbtest,
  
  WgtStickyLift,
  WgtTabsCut,
  WgtOldGoods,
  WgtOldEvaluate,
  WgtWallPaper,
   
  WgtAroundStoreOne,
  WgtProductShelves,
  WgtContentPart,
  WgtLift,
  WgtCustomWgt,
  WgtImageText,
  WgtMemberInfo,
  WgtSuspension,
  WgtUserContainer,
  WgtOfficialAccount
}


export default {
  widgets,
  initiWidgets
}
